@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@import "tailwindcss";

@theme {
  --font-pixel: "8bitOperatorPlus-Bold", "monospace";
  --font-pixel-regular: "8bitOperatorPlus-Regular", "monospace";

  --color-deltarune-yellow: #F9FF10;
  --color-deltarune-green: #00FF00;
  --color-deltarune-red: #FF2020;
  --color-deltarune-blue: #0000FF;
  --color-deltarune-gray: #7F7F7F;
  --color-deltarune-shadow: #0F0F70;
  --color-nav-blue: #342DB5;

  --animate-fade-in-top: fade-in-top 2s cubic-bezier(0.25, 1, 0.5, 1) forwards;
  --animate-fade-in: fade-in 1s ease-out forwards;
  --animate-pulse: pulse 6s steps(10, end) infinite;
  --animate-shadow: shadow 12s infinite ease-in-out;

  @keyframes fade-in-top {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: scale(0px);
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    50% { 
      opacity: 1.0;
    }
    100% { 
      opacity: 0.5;
    }
  }

  @keyframes shadow {
    0% {
      opacity: 0.2;
      transform: translateX(-10px);
    }
    25% {
      opacity: 0.3;
      transform: translateX(0px);
    }
    50% {
      opacity: 0.2;
      transform: translateX(10px);
    }
    75% {
      opacity: 0.3;
      transform: translateX(0px);
    }
    100% {
      opacity: 0.2;
      transform: translateX(-10px);
    }
  }
}

@font-face {
  font-family: '8bitOperatorPlus-Bold';
  src: url('/assets/fonts/8bitOperatorPlus-Bold.eot?#iefix') format('embedded-opentype'),  url('/assets/fonts/8bitOperatorPlus-Bold.woff') format('woff'), url('/assets/fonts/8bitOperatorPlus-Bold.ttf')  format('truetype'), url('/assets/fonts/8bitOperatorPlus-Bold.svg#8bitOperatorPlus-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: '8bitOperatorPlus-Regular';
  src: url('/assets/fonts/8bitOperatorPlus-Regular.eot?#iefix') format('embedded-opentype'),  url('/assets/fonts/8bitOperatorPlus-Regular.woff') format('woff'), url('/assets/fonts/8bitOperatorPlus-Regular.ttf')  format('truetype'), url('/assets/fonts/8bitOperatorPlus-Regular.svg#8bitOperatorPlus-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

.font-pixel {
  font-family: '8bitOperatorPlus-Bold', monospace;
}

.\!font-pixel {
  font-family: '8bitOperatorPlus-Bold', monospace !important;
}

.font-pixel-regular {
  font-family: '8bitOperatorPlus-Regular', monospace;
}

.\!font-pixel-regular {
  font-family: '8bitOperatorPlus-Regular', monospace !important;
}

.font-8bit {
  font-family: '8bitOperatorPlus-Bold', monospace;
}

.\!font-8bit {
  font-family: '8bitOperatorPlus-Bold', monospace !important;
}

.highlight-white {
  color: #FFFFFF;
  filter: drop-shadow(1px 1px var(--color-deltarune-shadow));
}

.highlight-yellow {
  color: var(--color-deltarune-yellow);
  filter: drop-shadow(1px 1px #4C4C00);
  line-height: 1.2;
  background-image: repeating-linear-gradient(to bottom, #FFFFC3 0em, #FFFF2C 1em, #FFFF2C 1.2em);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.highlight-blue {
  color: var(--color-deltarune-blue);
  filter: drop-shadow(1px 1px #00004C);
  line-height: 1.2;
  background-image: repeating-linear-gradient(to bottom, #C3C3FF 0em, #1C1CFF 1em, #1C1CFF 1.2em);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.highlight-red {
  color: var(--color-deltarune-red);
  filter: drop-shadow(1px 1px #4C0000);
  line-height: 1.2;
  background-image: repeating-linear-gradient(to bottom, #FFC3C3 0em, #FF1C1C 1em, #FF1C1C 1.2em);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.highlight-green {
  color: var(--color-deltarune-green);
  filter: drop-shadow(1px 1px #004C00);
  line-height: 1.2;
  background-image: repeating-linear-gradient(to bottom, #A8FFA8 0em, #0CFF0C 1em, #0CFF0C 1.2em);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
